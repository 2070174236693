@import url('https://fonts.googleapis.com/css2?family=Poppins');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* font-size: 14px; */
  font-family: 'Poppins', sans-serif !important;
}

.ant-menu-item > * {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-menu-item-selected {
  background-color: rgba(0, 119, 255, 0.1) !important;
}

.ant-menu-item-selected > * {
  /* color: white !important; */
  color: #0077ff !important;
}
.ant-pagination-options {
  display: none !important;
}
.ant-menu-item-content {
  white-space: normal;
}
.multi-line-label {
  white-space: normal;
}

div.ql-container.ql-bubble.ql-disabled > div.ql-editor {
  padding: 0;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* crear un difuminado de color gris en la parte baja de un div donde haya scroll */
.scrollbar-hide::-webkit-scrollbar-track {
  background: #f1f1f1;
}

@media screen and (max-width: 768px) {
  .ant-page-header{
    padding-inline-start: 20px !important;
    padding-inline-end: 20px !important;
  }
  .ant-pro-page-container-children-content{
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:last-child {
    display: none;
  }
  
  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }
  
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important; 
  }
}

.infoPostulaciones > .ant-descriptions .ant-descriptions-row > .ant-descriptions-item{
  padding-bottom: 0 !important;
}
